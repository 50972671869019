import React from 'react';
import NavigationBar from './NavBar';
import HomeContent from './Home';
import Footer from './Footer';

const LandingPage = () => {
    return (
        <div style={{backgroundColor:'#B7E0FF'}}>
            <NavigationBar />
            <HomeContent />
            <Footer />
        </div>
    );
};

export default LandingPage;
