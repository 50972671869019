import './App.css';

import LandingPage from './Components/LandingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './beevators_logo_64.png';

function App() {
  return (
    <div className="App">
      <LandingPage/>
    </div>
  );
}

export default App;
