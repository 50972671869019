import React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import logo from '../Assests/beevators_logo_128.png'

const NavigationBar = () => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed='top'>
            <Container>
                <Navbar.Brand >
                    <div >
                        <Image src={logo} style={{ width: 129, height: 32 }}></Image>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#products">Products</Nav.Link>
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavigationBar;
