import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import printB from '../Assests/printb_logo_144.png'

const HomeContent = () => {
    return (
        <Container className="mt-5" style={{ paddingTop:'120px',backgroundColor:'#B7E0FF'}}>
            <Row className="text-center mb-10">
                <Col>
                    <h2>Welcome to Beevators Technologies</h2>
                    <p>Empowering businesses with AI-driven innovation for a better tomorrow</p>
                </Col>
            </Row>
            <div style={{ height: '50px' }}></div>

            <Row id="about" className="mb-5">
                <Col sm='2'></Col>
                <Col>
                    <h3>About Us</h3>
                    <p >Beevators Technologies LLP is dedicated to providing small and medium-sized businesses with innovative, affordable, and future-ready solutions. We simplify complex operations, making them effective and accessible, and work closely with clients to deliver tailored technology solutions that modernize and strengthen business operations.</p>
                </Col>
                <Col sm='2'></Col>
            </Row>
            <div style={{ height: '30px' }}></div>
            <Row id="products" className="mb-5">
                <Col>
                    <h3>Our Products</h3>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Card>
                                <Card.Header style={{backgroundColor:'#000'}}>
                                    <Image src={printB} style={{ width: 129, height: 32 }}></Image>
                                </Card.Header>
                                <Card.Body style={{backgroundColor:'#C5D3E8'}}>

                                    <Card.Text>
                                        PrintBee is an all-in-one workflow and billing software crafted for digital printing businesses. It streamlines the entire process—from job orders and design proofs to production and invoicing—while offering AI-driven invoicing with OCR, real-time tracking, and detailed reporting. With easy integration and support for custom job specs, PrintBee helps optimize operations, reduce errors, and improve turnaround, delivering a seamless experience for clients.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}></Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col md={6}>
                    <Card>
                        <Card.Header style={{backgroundColor:'#000',color:'#FFF'}}>
                            <h5>Eazy ERP - Launching Soon</h5>
                        </Card.Header >
                        <Card.Body style={{backgroundColor:'#C5D3E8'}}>
                            <Card.Text>Easy ERP is a comprehensive management solution tailored for small and medium-sized businesses. It offers robust modules to streamline daily operations, from Workflow and Inventory Management to AI-driven Invoicing and HR Management. With Easy ERP, businesses can enhance productivity, monitor stock levels with automated replenishment, manage recruitment and performance, and leverage AI-powered invoicing for efficient billing. Designed for ease of use and scalability, Easy ERP empowers businesses to operate more effectively and drive growth.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}></Col>
            </Row>

            <Row id="services" className="mb-5">
                <div style={{ width: '100%', height: '50px' }}></div>
                <Col>
                    <h3>Our Services</h3>
                    <div>
                        <p>Custom Software Development</p>
                        <p>Mobile App Development</p>
                        <p>Cloud Solutions</p>
                        <p>Consulting & Support</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default HomeContent;
