import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="bg-dark text-light py-4">
            <Container>
                <Row>
                    <Col >
                        <h5>Beevators Technologies LLP</h5>
                        <p>MIG-A 176,Dr A S Rao Nagar, ECIL Post, 500062</p>
                        <p>WhatsApp: 9949403330</p>
                        <p>Mail: support@beevators.com</p>
                    </Col>

                </Row >
                <Row>
                    <Col  >
                        <p>&copy; {new Date().getFullYear()} Beevators Technologies LLP. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
